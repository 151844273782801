@import '../../assets/sass/variables';

$recentProjectsHeaderHeight: 60px;

.recentProjects { 
  min-height: $contentHeight;
  color: $white-color;
  text-align: center;
  background-color: $color-primary;
  .secondaryHeader {
    min-height: 150px;
    justify-content:initial;
  }
  @media screen and (max-width: $bp-sm) {
    min-height: unset;
    padding-bottom: $grid-giant;
  }
}
.recentProjectsHeaderContainer {
  background-color: $color-secondary;
  color: $white-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: $recentProjectsHeaderHeight;
}
.recentProjectsHeader {
  font-weight: bold;
  @media screen and (max-width: $bp-sm) {
    font-size: 22px;
  }
  @media screen and (max-width: $bp-sm) {
    font-size: 20px;
  }
}
.recentProjectsContainer {
  display: flex;
  align-items: center;
  min-height: calc(100vh - #{$recentProjectsHeaderHeight} - (#{$grid-large} * 2));
  height: 400px;
  width: 100%;
  background-color: $white-color;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
  @media screen and (max-width: $bp-sm) {
    align-items: initial;
    min-height: unset;
    height: auto;
  }
}
.projectSlide {
  img {
    max-width: 960px;
  }
}
.projectLegend {
  opacity: 1.0 !important;
}
.carouselSlider { 
  width: 100%;
  max-width: $container-width;
  margin-left: auto;
  margin-right: auto;
 }
 