$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;
$sizeScrollbar: 7px !default;

$white-color: #fafafa;
$offset-color: #eeeeee;
$black-color: #333333 !default;
$black-hr: #444444 !default;
$medium-gray: #f7f8fc !default;
$smoke-bg: #f5f5f5 !default;
$transparent-bg: transparent !default;
$dark-gray: #9a9a9a !default;
$light-gray: #e5e5e5 !default;
$color-primary: #cf2127 !default;
$color-secondary: #282828 !default;
$color-info: #4eb3d3;

$color-gray-primary: #adaeb0 !default;
$color-gray-normal: #dadada !default;
$color-orange: #ed6a5a;
$color-grey-blue: #8c96ab;
$color-disabled: rgba(0, 0, 0, 0.15);
$color-body: #505050;
$border-primary: solid $border-thick $color-primary;
$border-grey-primary: solid $border-thin $dark-gray;
$border-black: solid $border-thin $black-color;
$border-btn: solid $border-thick $color-secondary;
$border-grey: solid $border-thin $color-gray-primary;
$border-disabled: solid $border-thin $color-disabled;

$padding-small: 8px !default;
$padding-base: 12px !default;
$padding-base-vertical: 8px !default;
$padding-base-horizontal: 16px !default;

$padding-round-vertical: 9px !default;
$padding-round-horizontal: 18px !default;

$padding-simple-vertical: 10px !default;
$padding-simple-horizontal: 18px !default;

$padding-large-vertical: 14px !default;
$padding-large-horizontal: 30px !default;

$padding-small-vertical: 5px !default;
$padding-small-horizontal: 10px !default;

$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;

$padding-label-vertical: 2px !default;
$padding-label-horizontal: 12px !default;

$margin-large-vertical: 30px !default;
$margin-base-vertical: 15px !default;

$padding-zero: 0px !default;

$grid-tiny: 5px !default;
$grid-small: 10px !default;
$grid-base: 15px !default;
$grid-large: 30px !default;
$grid-giant: 50px !default;

$margin-bottom: 0 0 10px 0 !default;
$border-radius-small: 3px !default;
$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-extreme: 10px !default;
$btn-round-radius: 30px !default;

$font-size-base: 14px !default;
$font-size-small: 12px !default;
$font-size-medium: 16px !default;
$font-size-large: 18px !default;
$font-size-large-navbar: 20px !default;
$font-size-colossal: 24px;

$font-size-h1: 52px !default;
$font-size-h2: 32px !default;
$font-size-h3: 28px !default;
$font-size-h4: 22px !default;
$font-size-h5: 16px !default;
$font-size-h6: 14px !default;
$font-paragraph: 16px !default;
$font-size-navbar: 16px !default;
$font-size-small: 12px !default;
$font-size-medium: 18px !default;
$font-size-normal: 24px !default;
$font-size-pageTop: 32px !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semi: 500 !default;
$font-weight-bold: 600 !default;

$font-family: Lato, Arial, sans-serif;

$line-height-general: 1.5 !default;
$line-height-small: 14px !default;
$line-height: 20px !default;
$line-height-lg: 54px !default;

$field-width: 260px !default;

$bp-xs: 768px;
$bp-sm: 960px;
$bp-md: 1100px;
$bp-lg: 1920px;

$z-index-overlay: 100;

$color-info: #4eb3d3;
$theme-colors: (
  primary: $color-primary,
  secondary: #c6dbef,
  success: #a8ddb5,
  info: $color-info,
  warning: #fab3a9,
  danger: $color-orange,
);

$container-width: 1180px;

$footerHeight: 59px;
$mainHeight: calc(100vh - #{$footerHeight});
$headerHeight: 74px;
$contentHeight: 100vh;
$sectionHeight: calc(100vh - #{$grid-giant * 2});