@import '../../assets/sass/variables';
@import '../../assets/sass/general.scss';

$whyCardHeight: 320px;
$whyCardWidth: 320px;

.why {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: $sectionHeight;
  background-color: #212121;
  color: #fff;
  padding: $grid-giant;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
  @media screen and (max-width: $bp-sm) {
    min-height: unset;
  }
}
.whyHeader {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  @media screen and (max-width: $bp-sm) {
    font-size: 22px;
  }
  @media screen and (max-width: $bp-xs) {
    font-size: 20px;
  }
}
.whySubHeader {
  text-align: center;
  font-weight: 400;
  margin-top: $grid-tiny;
  margin-bottom: $grid-large;
}
.cardList {
  display: flex;
  max-width: $container-width;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: $bp-md) {
    max-width: 100%;
    flex-direction: column;
  }
}
.cardContainer {
  width: 33%;
  padding: 0 $grid-base 0 0;
  @media screen and (max-width: $bp-md) {
    padding: 0;
    width: 100%;
    margin-top: $grid-large;
    margin-bottom: $grid-large;
  }
}
.card {
  display: flex;
  flex-direction: column;
  max-width: $whyCardWidth;
  height: $whyCardHeight;
  margin-left: auto;
  margin-right: auto;
  background-color: $white-color;
  color: $black-color;
  @media screen and (max-width: $bp-md) {
    height: auto;
    max-width: 100%;
  }
}
.cardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $bp-md) {
    padding: $grid-large;
  }
}
.cardHeader {
  background-color: $color-primary;
  color: $white-color;
  text-align: center;
  padding: calc(#{$grid-small} + 5px);
  font-size: 18px;
  font-weight: bold;
}
.cardAvatar {
  margin-bottom: $grid-large;
  width: 100%;
  height: 60px;
  img {
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
.cardSubHeader {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  padding: 0 $grid-small 0 $grid-small;
}
.cardParagraph {
  padding: $grid-small $grid-large 0 $grid-large;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}
