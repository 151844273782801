@import '../../assets/sass/variables';
@import '../../assets/sass/general.scss';

.headerContainer {
  background-color: $color-primary;
  color: $white-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.expectations {
  display: flex;
  flex-direction: column;
  min-height: $contentHeight;
  text-align: center;
  margin: 0 auto 0 auto;
  @media screen and (max-width: $bp-sm) {
    min-height: unset;
  }
}
.expectationsContent {
  display: flex;
  align-items: center;
  flex-grow: 1;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.35);
}
.expectationsGrid {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  padding: $grid-giant;
}
.expectationsRow {
  display: flex;
  margin-bottom: $grid-large;
  @media screen and (max-width: $bp-xs) {
    flex-direction: column;
    &:first-child {
      margin-bottom: 0;
    }
  }
}
.expectationsCol {
  width: 50%;
  p { font-size: 18px; }
  @media screen and (max-width: $bp-xs) {
    width: 100%;
  }
}
.expactionsColData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $grid-base;
  @media screen and (max-width: $bp-xs) {
    border-bottom: solid 1px rgba(0,0,0,0.15);
    padding: $grid-large;
    p {
      margin-bottom: 0;
    }
  }
}
.expectationsHeader {
  font-size: 30px;
  font-weight: bold;
  margin-top: $grid-large;
  margin-bottom: 0;
  @media screen and (max-width: $bp-sm) {
    font-size: 26px;
  }
  @media screen and (max-width: $bp-xs) {
    font-size: 22px;
  }
}
.expectationsSubHeader {
  text-transform: uppercase;
  font-weight: 400;
  margin-top: $grid-tiny;
  margin-bottom: $grid-large;
  @media screen and (max-width: $bp-xs) {
    font-size: 14px;
  }
}
.cardIcon {
  width: 40px;
  height: 40px;
  opacity: 0.75;
  &:hover, &:focus {
    opacity: 1.0;
  }
}